import {Component, ViewChild} from '@angular/core';
import {IonMenu, IonSlides, Platform} from '@ionic/angular';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import {MenuController} from '@ionic/angular';
import { Router } from '@angular/router';
import {UtilService} from "./services/util.service";
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  @ViewChild(IonMenu) ionMenu: IonMenu

  constructor(
    private platform: Platform,
    public utilService: UtilService,
    private menu: MenuController,
  ) { }

  openEnd() {
    this.menu.close();
  }
}

import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'welkom',
    pathMatch: 'full'
  },
  {
    path: 'welkom',
    loadChildren: () => import('./main/main.module').then( m => m.MainPageModule)
  },
  {
    path: 'voorzieningen',
    loadChildren: () => import('./accomodation/accomodation.module').then( m => m.AccomodationPageModule)
  },
  {
    path: 'woningen',
    loadChildren: () => import('./buildings/buildings.module').then( m => m.BuildingsPageModule)
  },
  {
    path: 'omgeving',
    loadChildren: () => import('./environments/environments.module').then( m => m.EnvironmentsPageModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('./contact/contact.module').then( m => m.ContactPageModule)
  },  {
    path: 'privacy',
    loadChildren: () => import('./privacy/privacy.module').then( m => m.PrivacyPageModule)
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
